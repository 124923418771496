import { Blog, Note } from "@types"
import { User } from "@/types/users"
import Link from "next/link"
import { generatePostPreview } from "@/util/format"
import PostImage from "../PostImage"
import CategoryBadges from "components/CategoryBadges"
import { useSelector } from "react-redux"
import { selectCommunities } from "features/blogSlice"
import clsx from "clsx"
import UserAvatar from "components/UserAvatar"
import useNoteUrl from "hooks/useNoteUrl"
import useBlogTheme from "hooks/useBlogTheme"
import PostContentEditor from "./PostPage/Body/PostContentEditor"
import useEditorWrapper from "hooks/useEditorWrapper"

interface modelProps {
  note: Note
  discoverPage?: boolean
  blog: Blog
  user?: User
  titleIcon?: React.ReactNode
}

function FullPostSection(props: modelProps) {
  const { editor, editorReady, initialContent } = useEditorWrapper(
    props.note.json,
    props.note.text
  )

  if (!props.note.json) return null

  return (
    <PostContentEditor
      editor={editor}
      editorReady={editorReady}
      currentNote={props.note}
      initialContent={initialContent}
    />
  )
}

function DescriptionSection(props: modelProps) {
  const { style, showDescription } = useBlogTheme(
    props.blog,
    props.discoverPage
  )

  if (showDescription === false) return null
  const preview = generatePostPreview(props.note, 300)

  return (
    <p
      className={clsx(
        style === "xl" && "text-lg mt-2",
        style === "lg" && "text-lg mt-2",
        style === "md" && "text-md mt-2",
        style === "sm" && "text-sm mt-2",
        style === "xs" && "text-xs mt-2",
        "leading-7 text-custom-700"
      )}
      dangerouslySetInnerHTML={{ __html: preview }}
    ></p>
  )
}

function BadgesSection({ note, ...props }: modelProps) {
  const communities = useSelector(selectCommunities)

  const { coverImgPosition } = useBlogTheme(props.blog)
  if (!note) return null
  if (props.blog.allPostsTheme?.showTags === false) return null

  const hasBadges =
    (communities && communities.length > 0) ||
    (note?.categories && note.categories.length > 0)

  if (!hasBadges) return null

  if (props.blog.allPostsTheme?.style === "xs") return null

  return (
    <div
      className={clsx(
        coverImgPosition === "above" ? "order-last" : "",

        "pt-4 "
      )}
    >
      <CategoryBadges
        communities={communities}
        communityColor="yellow"
        categoryColor="green"
        showCategories={true}
        note={note}
        showAddCategory={false}
        clickable={!props.discoverPage}
      />
    </div>
  )
}

function DiscoverPageHeader({ date, ...props }: modelProps & { date: Date }) {
  return (
    <div className="flex justify-between space-x-3 w-full">
      <div className="flex flex-1 space-x-3">
        {props.user?.avatar_url && (
          <div className="flex-shrink-0">
            <UserAvatar
              height={40}
              width={40}
              className="w-10 h-10 rounded-full"
              url={props.user.avatar_url}
            />
          </div>
        )}
        <div className="flex-1 min-w-0">
          <p className="text-sm font-semibold text-custom-900">
            {props.blog.name}
          </p>

          <p className="text-sm text-custom-500">{props.user?.authorName}</p>
        </div>
      </div>

      <div>
        <time
          dateTime={date.toISOString()}
          className="text-sm leading-7 flex align-middle justify-end text-custom-500"
        >
          {new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            timeZone: "UTC",
          }).format(date)}
        </time>
      </div>
    </div>
  )
}

function TitleSection(props: modelProps) {
  const { fullPost, style, showTitle } = useBlogTheme(
    props.blog,
    props.discoverPage
  )

  const { fullNoteUrl } = useNoteUrl({ blog: props.blog, note: props.note })

  if (showTitle === false) return null

  const styles = clsx(
    "flex items-top",
    style === "xl" && "text-3xl font-bold mt-2",
    style === "lg" && "text-2xl font-bold mt-2",
    style === "md" && "text-xl font-bold mt-2",
    style === "sm" && "text-lg",
    style === "xs" && "text-md",
    fullPost ? "hover:text-primary cursor-pointer" : "post-list-title",

    "text-custom-900 transition-all duration-300"
  )

  const title = props.note?.title || "Untitled post"

  if (fullPost) {
    return (
      <h2>
        <Link href={fullNoteUrl} className={styles}>
          {title}
        </Link>
      </h2>
    )
  }

  return (
    <h2 className={styles}>
      {props.titleIcon && <div className="mr-2 mt-1">{props.titleIcon}</div>}
      {props.note?.title || "Untitled post"}
    </h2>
  )
}

function ImageSection({ note, ...props }: modelProps) {
  const { gridCols, showCoverImg, coverImgPosition } = useBlogTheme(
    props.blog,
    props.discoverPage
  )

  const classNames = ""

  if (showCoverImg === false) return null

  const emptyDiv = <div></div>

  if (!note) return emptyDiv

  const emptyPostImage = PostImage({ note }) === null

  if (emptyPostImage) return emptyDiv

  return (
    <PostImage
      note={note}
      className={clsx(
        props.discoverPage && "!mb-4",
        coverImgPosition === "above" && "w-full mx-auto",
        coverImgPosition === "above" && gridCols > 1 && "order-last !mt-4",
        coverImgPosition === "left" &&
          " lg:mr-4 lg:!w-1/2 lg:basis-0 lg:grow self-center lg:h-full order-last lg:order-first !mt-4",
        coverImgPosition === "right" &&
          "sm:order-last  !sm:w-1/2 sm:ml-4 sm:basis-0 sm:grow self-center h-full lg:ml-4",
        "flex justify-center group-hover:opacity-80 transition-opacity duration-300",
        classNames
      )}
    />
  )
}

function LinkElement({
  children,
  fullPost,
  discoverPage,
  fullNoteUrl,
}: {
  children: React.ReactNode
  fullPost: boolean
  discoverPage?: boolean
  fullNoteUrl: string
}) {
  if (fullPost) return <>{children}</>

  return (
    <Link href={fullNoteUrl} target={discoverPage ? "_blank" : undefined}>
      {children}
    </Link>
  )
}

export function AllPostsListItem(props: modelProps) {
  const { fullNoteUrl } = useNoteUrl({ blog: props.blog, note: props.note })
  const note = props.note
  const date = new Date(
    note?.publishedAt || note?.updatedAt || note?.createdAt || "2022-01-01"
  )

  const { fullPost, gridCols, coverImgPosition, onlyTitle, style } =
    useBlogTheme(props.blog, props.discoverPage)

  let containerStyles = ""

  let badgesBelowImage = false

  if (!props.discoverPage) {
    if (gridCols === 1 && !onlyTitle && !fullPost) {
      containerStyles =
        "hover:shadow-sm hover:border-custom-100 duration-150 transition-all hover:z-5"
    } else if (!onlyTitle && !fullPost) {
      containerStyles = "border-custom-100 border rounded-xl py-5"
    }

    if (props.blog.allPostsTheme?.style === "xs") {
      containerStyles += " !py-1"
    }

    badgesBelowImage = gridCols > 1 && coverImgPosition === "above"
  }

  let fullPostStyles = ""

  if (!fullPost) {
    fullPostStyles = "hover: bg-custom-50 hover:cursor-pointer"
  }

  return (
    <div
      aria-labelledby={`${note?.slug}-title`}
      className={clsx(style === "xs" ? "py-1" : "py-4", "h-full")}
    >
      <LinkElement
        fullNoteUrl={fullNoteUrl}
        discoverPage={props.discoverPage}
        fullPost={fullPost}
      >
        <div
          className={clsx(
            containerStyles,
            fullPostStyles,
            "relative group flex flex-col items-start px-6 py-2 sm:py-6 rounded-2xl transition-all duration-100  border border-transparent h-full",
            props.discoverPage ? "space-y-4" : ""
          )}
        >
          <div
            className={clsx(
              coverImgPosition === "above" && "flex-col  w-full",
              ["left", "right"].includes(coverImgPosition) &&
                "flex-col lg:flex-row w-full",
              "flex  h-full "
            )}
          >
            {/** Only show the image section if we are not displaying the full post. */}
            {!fullPost && <ImageSection {...props} />}
            <div
              className={clsx(
                gridCols === 1 && "justify-center",

                "flex flex-col basis-0 grow justify-between"
              )}
            >
              <div className="flex justify-between flex-col h-full">
                <div>
                  <div className="flex items-center w-full">
                    {props.discoverPage ? (
                      <DiscoverPageHeader {...props} date={date} />
                    ) : (
                      props.blog.allPostsTheme?.showDate !== false && (
                        <time
                          dateTime={date.toISOString()}
                          className="text-sm leading-7 text-custom-500"
                        >
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            timeZone: "UTC",
                          }).format(date)}
                        </time>
                      )
                    )}
                  </div>
                  <TitleSection {...props} />
                  {fullPost ? (
                    <FullPostSection {...props} />
                  ) : (
                    <DescriptionSection {...props} />
                  )}
                </div>
                {!badgesBelowImage && <BadgesSection {...props} />}
              </div>
            </div>
            {badgesBelowImage && <BadgesSection {...props} />}
          </div>
        </div>
      </LinkElement>
    </div>
  )
}
