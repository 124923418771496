import { Blog, Note } from "@types"
import useBlogTheme from "hooks/useBlogTheme"

import { AllPostsListItem } from "./AllPostsListItem"

import Pagination from "components/Pagination"
import { useSelector } from "react-redux"
import { selectPaginationNoteSize } from "features/noteSlice"
import {
  selectBlog,
  selectBlogIntroduction,
  selectCommunities,
} from "features/blogSlice"
import SubscribeButton from "components/SubscribeButton"
import clsx from "clsx"
import { TiPin } from "react-icons/ti"
import BlogIntroductionReadOnly from "components/settings/theme/BlogIntroductionReadOnly"

function EmptyState({ blog }: { blog: Blog }) {
  return (
    <div className="">
      <div className="flex flex-col pt-12 justify-center items-center align-middle mx-auto my-4 sm:my-14 px-6">
        <div className="text-center text-4xl text-custom-900 font-bold">
          No posts yet
        </div>
        <div className="text-base text-custom-500 my-1 pb-3">
          Subscribe to be notified when {blog.name} publishes their first post.
        </div>
        <SubscribeButton />
      </div>
    </div>
  )
}

interface modelProps {
  filteredNotes: Array<Note> | undefined
  pinnedPosts: Array<Note> | undefined
  blog: Blog
  pageNum: number
  community: string
  category: string
}

export default function AllPostsSkeleton(props: modelProps) {
  const paginationNoteSize = useSelector(selectPaginationNoteSize)
  const blog = useSelector(selectBlog)
  const communities = useSelector(selectCommunities)
  const blogIntroduction = useSelector(selectBlogIntroduction)

  const community = communities?.find((c) => c.name == props.community)

  const { style, gridCols } = useBlogTheme(blog)

  return (
    <>
      <div
        className={clsx(
          gridCols > 1
            ? "lg:max-w-6xl mx-auto px-0 sm:px-4"
            : "lg:max-w-4xl mx-auto"
        )}
      >
        {blogIntroduction && (
          <BlogIntroductionReadOnly blogIntroduction={blogIntroduction} />
        )}

        {props.community != "" && community && (
          <div className="flex flex-col ml-6 mb-8 mt-16 border-b-2 pb-6 text-custom-900">
            <span className="uppercase tracking-wide text-3xl font-bold">
              {community.name}
            </span>
            <span className="tracking-wide text-md mt-4">
              {community.description}
            </span>
          </div>
        )}
        <div
          className={clsx(
            gridCols == 1 && "divide-y divide-custom-100",
            gridCols == 1 && "grid grid-cols-1 ",
            gridCols == 2 && "grid sm:grid-cols-2 sm:gap-4",
            gridCols == 3 && "grid sm:grid-cols-2 md:grid-cols-3 sm:gap-4",
            gridCols == 4 && "grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-2",
            style === "xs" && "!gap-0"
          )}
        >
          {props.pinnedPosts?.map((note, idx) => (
            <div key={`${idx}-${note?.id}`} className="relative">
              <AllPostsListItem
                note={note}
                blog={blog}
                key={`${idx}-${note?.id}`}
                titleIcon={<TiPin className="h-6 w-6 text-primary-600" />}
              />
            </div>
          ))}

          {props.filteredNotes?.map((note, idx) => (
            <AllPostsListItem
              note={note}
              blog={blog}
              key={`${idx}-${note?.id}`}
            />
          ))}

          <ConditionallyShowEmptyState {...props} />
        </div>

        <div className="bg-custom-50 rounded-xl my-4">
          <Pagination
            displayNumbers={true}
            paginationNoteSize={paginationNoteSize}
            activePage={props.pageNum}
            category={props.category}
            community={props.community}
          />
        </div>
      </div>
    </>
  )
}

function ConditionallyShowEmptyState({
  filteredNotes,
  pinnedPosts,
  blog,
}: {
  filteredNotes: Array<Note> | undefined
  pinnedPosts: Array<Note> | undefined
  blog: Blog
}) {
  const hasPosts = filteredNotes && filteredNotes.length > 0
  const hasPinnedPosts = pinnedPosts && pinnedPosts.length > 0

  if (hasPosts || hasPinnedPosts) {
    return null
  }

  return <EmptyState blog={blog} />
}
