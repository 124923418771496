import { Note } from "@types"
import FutureImage from "next/image"
import clsx from "clsx"

// This is used in the AllPosts page. NOT the cover photo.
export default function PostImage({
  note,
  className,
}: {
  note: Note
  className?: string
}) {
  if (!note) return null

  // If there's a cover image, just use that.
  if (note.cover_img) {
    return (
      <div
        className={clsx(
          className,
          "relative block w-full max-h-[422px] my-3 sm:my-0"
        )}
      >
        <FutureImage
          alt="Cover photo"
          src={note.cover_img.img.src}
          className="object-cover object-center rounded-2xl mb-2 mx-auto border border-gray-150"
          placeholder="blur"
          width={note.cover_img.img.width}
          height={note.cover_img.img.height}
          sizes="(min-width: 1024px) 848px, 100vw"
          blurDataURL={note.cover_img.base64}
        />
      </div>
    )
  }

  // If no cover image -> don't show anything
  return null
}
