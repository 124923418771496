import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid"
import Link from "next/link"

import useColor from "hooks/useColor"
import { useSelector } from "react-redux"
import { selectBlog } from "features/blogSlice"
import useBlogUrl from "hooks/useBlogUrl"
import useBlogTheme from "hooks/useBlogTheme"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

function getPageRange(
  currentPage: number,
  totalPageCount: number,
  numPagesToShow = 3
) {
  const pages = []

  if (totalPageCount <= 7) {
    for (let i = 1; i <= totalPageCount; i++) {
      pages.push(i)
    }
    return pages
  }

  // Add the first few pages
  for (let i = 1; i <= numPagesToShow; i++) {
    pages.push(i)
  }

  // Determine the range around the current page
  const startMiddleRange = Math.max(numPagesToShow + 1, currentPage - 1)
  const endMiddleRange = Math.min(
    currentPage + 1,
    totalPageCount - numPagesToShow
  )

  // Add ellipsis if there is a gap between start pages and middle range
  if (startMiddleRange > numPagesToShow + 1) {
    pages.push("...")
  }

  // Add the middle range pages
  for (let i = startMiddleRange; i <= endMiddleRange; i++) {
    pages.push(i)
  }

  // Add ellipsis if there is a gap between middle range and end pages
  if (endMiddleRange < totalPageCount - numPagesToShow) {
    pages.push("...")
  }

  // Add the last few pages
  for (let i = totalPageCount - numPagesToShow + 1; i <= totalPageCount; i++) {
    pages.push(i)
  }

  return pages
}

export default function Pagination(props: {
  paginationNoteSize: number
  displayNumbers?: boolean
  activePage: number
  category?: string
  community?: string
}) {
  const blog = useSelector(selectBlog)
  const { customDomainOrRelativeUrl } = useBlogUrl({ blog })
  const { postsPerPage } = useBlogTheme(blog)

  const color = useColor()

  if (props.paginationNoteSize <= postsPerPage) {
    return null
  }

  const nextPage = (newPage: number | string) => {
    let url = customDomainOrRelativeUrl
    const numberPage = parseInt(newPage as string)

    if (props.category) {
      url += `category/${props.category}/`
    } else if (props.community) {
      url += `community/${props.community}/`
    }

    if (numberPage > 1) {
      url += `page/${newPage}`
    }

    return url
  }

  return (
    <nav
      className="flex items-center justify-between  border-gray-200 sm:px-4 pb-8 px-4 "
      aria-label="Pagination"
    >
      <div className="flex flex-1 w-0 -mt-px">
        {props.activePage !== 1 && (
          <Link
            href={{
              pathname: nextPage(props.activePage - 1),
            }}
            shallow={false}
            scroll={true}
            className="inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-200 cursor-pointer"
          >
            <ArrowLongLeftIcon
              className="w-5 h-5 mr-3 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </Link>
        )}
      </div>
      {props.displayNumbers && (
        <div className="hidden md:-mt-px md:flex">
          {getPageRange(
            props.activePage,
            Math.ceil(props.paginationNoteSize / postsPerPage)
          ).map((page, i) => {
            if (page === "...") {
              return (
                <span key={i} className="px-4 pt-4 text-sm font-medium">
                  ...
                </span>
              )
            } else {
              return (
                <Link
                  href={{
                    pathname: nextPage(page),
                  }}
                  key={i}
                  shallow={false}
                  scroll={true}
                  style={{
                    color: props.activePage === page ? color.primary : "",
                    borderColor: props.activePage === page ? color.primary : "",
                  }}
                  className={classNames(
                    "inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-200 cursor-pointer"
                  )}
                >
                  {page}
                </Link>
              )
            }
          })}
        </div>
      )}
      <div className="flex justify-end flex-1 w-0 -mt-px">
        {props.activePage * postsPerPage < props.paginationNoteSize && (
          <Link
            href={{
              pathname: nextPage(props.activePage + 1),
            }}
            shallow={false}
            scroll={true}
            className="inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-200 cursor-pointer"
          >
            Next
            <ArrowLongRightIcon
              className="w-5 h-5 ml-3 text-gray-400"
              aria-hidden="true"
            />
          </Link>
        )}
      </div>
    </nav>
  )
}
