import { Blog, allPostsTheme } from "@types"

const DEFAULTS: Required<allPostsTheme> = {
  showCoverImg: true,
  coverImgPosition: "above",
  showTitle: true,
  showTags: true,
  style: "md",
  gridCols: 1,
  showDescription: true,
  showReadMore: true,
  showDate: true,
  postsPerPage: 10,
  fullPost: false,
}

/**
 * If forceDefault is passed, we want to force the default theme.
 *
 * This is used on the Discover page, where we don't want to use custom
 * themes for the postlist entries.
 */
export default function useBlogTheme(blog: Blog, forceDefault?: boolean) {
  if (forceDefault)
    return {
      ...DEFAULTS,
      onlyTitle: false,
    }

  const style = blog.allPostsTheme?.style || DEFAULTS.style
  const gridCols = blog.allPostsTheme?.gridCols || DEFAULTS.gridCols
  const onlyTitle =
    blog.allPostsTheme?.showDate === false &&
    blog.allPostsTheme?.showDescription === false &&
    blog.allPostsTheme?.showReadMore === false &&
    blog.allPostsTheme?.showCoverImg === false

  const coverImgPosition =
    blog.allPostsTheme?.coverImgPosition || DEFAULTS.coverImgPosition
  const postsPerPage = blog.allPostsTheme?.postsPerPage || DEFAULTS.postsPerPage

  return {
    ...blog.allPostsTheme,
    onlyTitle,
    style,
    gridCols,
    coverImgPosition,
    postsPerPage,
    fullPost: blog.allPostsTheme?.fullPost || DEFAULTS.fullPost,
  }
}
