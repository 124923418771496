import { BlogIntroduction } from "@types"
import TipTapEditor from "components/Editor/TipTapEditor"
import useEditorWrapper from "hooks/useEditorWrapper"
import { useEffect, useState } from "react"
import clsx from "clsx"

import { postClassNames } from "@/util/constants/style"

export default function BlogIntroductionReadOnly({
  blogIntroduction,
}: {
  blogIntroduction: BlogIntroduction
}) {
  const { editor, editorReady, initialContent } = useEditorWrapper(
    blogIntroduction.json
  )
  const [clientSide, setClientSide] = useState(false)

  useEffect(() => {
    setClientSide(true)
  }, [])

  if (clientSide && editor && editorReady) {
    return (
      <div className="mx-7 md:mx-6">
        <TipTapEditor editor={editor} />
      </div>
    )
  }

  if (!blogIntroduction.staticHtml) {
    return null
  }

  return (
    <div className="mx-7 md:mx-6">
      <div
        className={clsx(postClassNames, "tiptap ProseMirror")}
        dangerouslySetInnerHTML={{
          __html: blogIntroduction.staticHtml,
        }}
      />
    </div>
  )
}
